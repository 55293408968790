import * as React from "react"
import Seo from "../components/seo"
import { StaticQuery, graphql } from "gatsby"
import ProjectCard from '../components/ProjectCard';
import { Link } from "gatsby"
import Navigation from "../components/navigation";
import Footer from "../components/footer";
import * as styles from "../components/pages/projects.module.css"


const Projects = () => {

  const horizontalScroll = (e) => {
    const container = document.getElementById('projectsRef')
    const containerScrollPosition = document.getElementById('projectsRef').scrollLeft
    container.scrollTo({
        left: containerScrollPosition + e.deltaY,
        behaviour: 'smooth'
    })
}

            
  return(
  <StaticQuery
  query={graphql`
    query MyQuery {
      allStrapiProject {
        nodes {
          title
          strapi_id
          color
          categories {
            name
          }
          date
          cover_img {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, width: 450, formats: PNG)
              }
            }
          }
        }
      }
    }
  `}

  render={data => (
    <div>
      <div  className={styles.pageWrapper}  id="pageWrapper" onWheel={horizontalScroll}>
        <Navigation/>
        <div className={styles.contentWrapper}  >
          <h1 className={styles.title}>Projecten</h1>
          <div  className={styles.projects} id="projectsRef" >
          {data.allStrapiProject.nodes.map((res, i) => (
                    <ProjectCard key={i} project={res} />      
          ))}
          </div>
          <Link to={`/lijstje`} className={styles.buttonProject}>
            <p>Bekijk archief</p>  
          </Link>
        </div>
          
        <div>
          <Footer/>
        </div>
      </div>
    </div>
  )}
  
  />
  
              )
            
            }

export const Head = () => <Seo title="Projecten" />

export default Projects

