import * as React from "react"
import { Link } from "gatsby"
import * as styles from "../components/ProjectCard.module.css"

import { GatsbyImage, getImage } from "gatsby-plugin-image"


const ProjectCard = ({ project }) => {
    
const image = getImage(project.cover_img.localFile);

    return (
        <Link
        to={`/projects/${project.strapi_id}`}
        key={project.strapi_id}
        className={styles.card}
        >
            <div className={styles.cardText} >
                <p className={styles.title}>{project.title}</p>
                <div className={styles.categories}>
                {project.categories.map(category => (
                    <p key={category.name} className={styles.category}>{category.name}&nbsp; </p> 
                ))}
                </div>
            </div>
                <GatsbyImage
                className={styles.cover}
                alt="Linked link"
                image={image}/>
           
        </Link>
    )};

export default ProjectCard;
